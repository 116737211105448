<template>
  <UiPopup
    :model-value="modelValue"
    title="You finished a call"
    description="Please fill in the call outcome."
    primary-button-text="Save"
    primary-button-full-width
    :loading="saving"
    hide-close-button
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <UiLoader v-if="loading" />
    <form v-else ref="form" class="flex gap-4" @submit.prevent>
      <div
        v-if="fullOutcome"
        class="w-full rounded-2xl border-2 border-primary-10 bg-white p-3 text-left transition"
        :class="{ '!border-error-100': useGetFieldErrors(v$, ['outcome']) }"
      >
        <span class="mb-3 block text-base font-medium text-black-90">Outcome:</span>
        <UiInputRadio id="outcome-type" v-model="data.outcome" vertical :items="items" />
      </div>
      <div
        class="min-h-36 w-full rounded-2xl border-2 border-primary-10 bg-white p-3 text-left transition"
        :class="{ '!border-error-100': useGetFieldErrors(v$, ['quality']), '!text-center': !fullOutcome }"
      >
        <span class="text-base font-medium text-black-90">Connection quality:</span>
        <div class="flex h-full items-center justify-center">
          <UiIcon
            name="thumbs-up"
            :class="{ 'fill-primary-20': data.quality === CALL_OUTBOUND_CONNECTION.GOOD }"
            size="lg"
            class="mr-6 size-12 cursor-pointer text-primary-80 transition"
            @click="data.quality = CALL_OUTBOUND_CONNECTION.GOOD"
          ></UiIcon>
          <UiIcon
            name="thumbs-down"
            :class="{ 'fill-primary-20': data.quality === CALL_OUTBOUND_CONNECTION.BAD }"
            size="lg"
            class="size-12 cursor-pointer text-primary-80 transition"
            @click="data.quality = CALL_OUTBOUND_CONNECTION.BAD"
          ></UiIcon>
        </div>
      </div>
    </form>
  </UiPopup>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, requiredIf } from '@vuelidate/validators'
import type { CallId, Outcome, OutcomeCallInfo } from '@/types'
import { CALL_LOGS_STATUSES, CALL_OUTBOUND_CONNECTION } from '@/constants'
import { useUiStore } from '@/store/ui'

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'input'): void
}>()

type Props = {
  modelValue: boolean
  callId: CallId
  fullOutcome: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  fullOutcome: true,
})

const data = reactive<OutcomeCallInfo>({
  outcome: null,
  quality: null,
})

const outcomeTypes = ref<Outcome[] | null>(null)

const items = computed(() => {
  if (!outcomeTypes.value) return []

  return outcomeTypes.value
    .filter(({ code }) => ![CALL_LOGS_STATUSES.MISSED, CALL_LOGS_STATUSES.NO_ANSWER].includes(code))
    .map(({ name, id }) => ({ id, text: name, value: id }))
})

const rules = computed(() => ({
  outcome: { required: helpers.withMessage('The outcome is required', requiredIf(props.fullOutcome)) },
  quality: { required: helpers.withMessage('The quality is required', required) },
}))

const loading = ref(true)
const saving = ref(false)

onNuxtReady(async () => {
  loading.value = true
  outcomeTypes.value = await useCallOutboundOutcomes()
  loading.value = false
})

const v$ = useVuelidate(rules, data)

const uiStore = useUiStore()

const submit = async () => {
  const isValid = await v$.value.$validate()

  if (isValid) {
    const payload = {
      quality: data.quality!,
    }
    if (props.fullOutcome) payload.outcome = data.outcome

    try {
      saving.value = true

      await useVoiceCallOutcome(props.callId, payload)

      emits('update:modelValue', false)
      emits('input')
    } catch (e: any) {
      uiStore.showSnackBanner(e.message, 'error')
    } finally {
      saving.value = false
    }
  }
}
</script>

<style scoped lang="scss"></style>
